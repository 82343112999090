<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app>
    <v-navigation-drawer
      v-show="visible"
      v-model="controls.drawer"
      :width="visible ? '322px' : '0'"
      floating
      app
    >
      <img
        :src="require('@/assets/icons/less_than.png')"
        width="18px"
        height="18px"
        class="ma-6"
        style="float: right; cursor: pointer"
        v-show="visible"
        @click="visible = !visible"
      />

      <v-layout
        align-center
        justify-center
        column
        class="app-logo-container"
        style="height: 322px"
      >
        <div class="app-logo-sheet">
          <v-img
            :src="require('@/assets/images/villy-logo.png')"
            width="100px"
            height="100px"
            class="ma-6"
          ></v-img>
        </div>
        <div class="app-company--name mt-8">Villy</div>
        <div class="app-project--name mt-2">Admin CMS</div>
      </v-layout>

      <v-list class="app-nav" flat>
        <v-subheader class="mb-5">
          <div class="app-nav--title mx-auto">NAVIGATION</div>
        </v-subheader>
        <v-list-item
          link
          active-class="white black--text"
          v-for="item in navItems"
          :key="item.label"
          :to="item.to"
        >
          <v-list-item-content>
            <v-list-item-title class="pa-2 app-nav--item">{{
              item.label
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-menu top offset-y>
            <template v-slot:activator="{ on }">
              <v-list-item two-line v-on="on">
                <v-list-item-avatar>
                  <user-avatar size="40" :avatar="user.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.full_name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.email ? user.email : user.phone_number }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'user.details', params: { id: user.id } }"
              >
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logoutConfirmDialog = true">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-content class="main-content">
      <div
        style="float: left; cursor: pointer"
        v-show="!visible"
        @click="reload()"
      >
        <v-img
          :src="require('@/assets/icons/greater_than.png')"
          width="18px"
          height="18px"
          class="ma-6"
        ></v-img>
      </div>
      <div class="px-xl-12 px-lg-10 px-md-6 px-sm-4 px-xs-2">
        <slot />
      </div>
    </v-content>
    <ConfirmModal
      v-model="logoutConfirmDialog"
      title="Logout"
      message="Are you sure you want to logout?"
    >
      <v-btn text @click="logoutConfirmDialog = false">Cancel</v-btn>
      <v-btn color="primary" text @click="logout">Logout</v-btn>
    </ConfirmModal>
  </v-app>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapState, mapActions, mapGetters } from 'vuex'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'MainLayout',

  components: {
    UserAvatar,
    ConfirmModal,
  },

  mixins: [ControlsMixin],

  data() {
    return {
      visible: true,
      navItems: [
        // { label: 'Inbox', to: { name: 'inbox' } },
        { label: 'Users', to: { name: 'users' } },
        { label: 'Quests', to: { name: 'quests' } },
        { label: 'Certification', to: { name: 'certification' } },
        // { label: 'Content', to: { name: 'content' } },
        // { label: 'Notifications', to: { name: 'notification' } },
        { label: 'Settings', to: { name: 'settings' } },
        { label: 'Reports', to: { name: 'reports' } },
      ],
      logoutConfirmDialog: false,
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters({
      avatarThumb: 'auth/avatarThumb',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    reload() {
      location.reload()
    },
  },
}
</script>
